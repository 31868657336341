import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';
import "./FuelSystemLines.css";
import { fuel, siteList, statesList, statusList } from './data';
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import FuelSystemLinesForm from './FuelSystemLinesForm';
import { Dialog } from 'primereact/dialog';
import { useErrorHandler } from '../../services/errorhandler';
import { RolesService } from '../../services/roles.service.ts';
import { useSelector } from 'react-redux';
import { AnalyticsService } from '../../services/analytics.service.ts';
const FuelSystemLines = () => {
  const [fuelData, setFuelData] = useState([]);
  const { profile } = useSelector((state) => state.auth);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedRow, setExpandedRow] = useState(null);
  // const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredData, setFilteredData] = useState(fuelMapping);
  const [filters, setFilters] = useState({ dates: null });
  useEffect(() => {
    setFuelData(fuel);
    setLoading(false);
  }, [])

  const getSiteDetails = () => {
    const payload = {
      site_id: profile.sites,
      client_id: profile.client,
    };

    AnalyticsService.getSites(payload)
      .pipe(errorHandler("Get Sites"))
      .subscribe((response) => {
        if (response?.data) {
          const facilityOptions = response?.data.map((facility) => ({
            label: facility.facility_name,
            value: facility.site_id,
            state: facility.state,
          }));
          setSites(facilityOptions);
        }
      });
  };

  useEffect(() => {
    getSiteDetails();
  }, []);

  const uniqueStates = [
    ...new Set(sites.map((facility) => facility.state)),
  ];
  const stateOptions = uniqueStates.map((state) => ({
    label: state,
    value: state,
  }));

  const numberEditor = (options) => {
    return <InputNumber
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
    />
  }

  const getFuelMapping = () => {
    const payload = {
      client_id: profile.client,
      site_id: profile.sites
    }
    setIsLoading(true);
    RolesService.getFuelLineData(payload).pipe(errorHandler("Get Fuel Mapping")).subscribe((response) => {
      if (response?.data) {
        setFuelMapping(response?.data);
        setFilteredData(response?.data)
      }
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getFuelMapping();
  }, []);

  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps.rowEditor.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps.rowEditor.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => console.log(rowData)}
        />
      </>
    );
  }

  const exportCSV = () => {
    const csvContent = [];
    csvContent.push(["client_name", " facility_name", "Total_Volumne", "Fule_System_Name", "Period_Start", "Period End", "Next_Reading", "Periodic_Sales", "Periodic_Deliveries", "Over_Under", "result", "Last_Reading",]);

    fuelMapping.forEach(parentRow => {
      csvContent.push([
        parentRow.client_name,
        parentRow.facility_name,
        parentRow.Total_Volumne,
        parentRow.Fule_System_Name,
        parentRow.Period_Start,
        parentRow.Period_End,
        parentRow.Next_Reading,
        parentRow.Periodic_Sales,
        parentRow.Periodic_Deliveries,
        parentRow.Over_Under,
        parentRow.result,
        parentRow.Last_Reading,

      ]);

      csvContent.push(['', "Tank_Number", "Beginning_Inventory_Volume", "Product", "Actual_Ending_Inventory_Volume", "Sales", "Deliveries", "book_value", "only_date", "Cumsum",]);
      parentRow.parent.child.forEach(childRow => {
        csvContent.push([
          '',
          childRow.Tank_Number,
          childRow.Beginning_Inventory_Volume,
          childRow.Product,
          childRow.Actual_Ending_Inventory_Volume,
          childRow.Sales,
          childRow.Deliveries,
          childRow.book_value,
          childRow.only_date,
          childRow.Cumsum,
          '',
          ''
        ]);
      });
      csvContent.push([]);
    });

    const csvString = csvContent.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel-system-lines.csv";
    link.click();
  };

  const headerElement = (
    <div className='fw-semibold mx-4 mt-3'>
      {`Create Fuel System Lines`}
    </div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`; // Return in mm/dd/yyyy format
  };

  const formatNumber = (value) => {
    return value ? value.toFixed(2) : '0.00';
  }

  const onFilter = () => {
    if ((!filters.dates || filters.dates.length !== 2) && !selectedFacilities?.length) {
      setFilteredData(fuelMapping);  // Reset to original data if no filters are applie 
      return;
    }

    let filtered = [...fuelMapping];

    if (selectedFacilities?.length) {
      filtered = filtered.filter(item => selectedFacilities.includes(item?.parent?.site_id));
    }
    if (selectedStates?.length) {
      filtered = filtered.filter(item => selectedStates.includes(item?.parent?.site_id));
    }

    if (filters.dates && filters.dates.length === 2) {
      const [startDate, endDate] = filters.dates;
      filtered = filtered.filter(row => {
        const periodStart = new Date(row.parent.Period_Start);
        const periodEnd = new Date(row.parent.Period_End);

        return (
          (periodStart >= startDate && periodStart <= endDate) ||
          (periodEnd >= startDate && periodEnd <= endDate)
        );
      });
    }

    setFilteredData(filtered);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <div className="d-flex flex-wrap justify-content-end grid_buttons gap-2">
          <Button label="New Reading" text onClick={() => setFormData({})} />
          <Button label="Delete" text className="delete-button" disabled={!selectedProducts[data.id]?.length || selectedProducts[data.id]?.length < 2} />
        </div>
        <DataTable
          selectionMode={"checkbox"}
          value={data.parent.child || []}
          id="fuel-lines-grid"
          dataKey="_id"
          selection={selectedProducts[data.id]}
          showSelectAll={false}
          editMode="row"
          scrollable
          scrollHeight={"250px"}
          onSelectionChange={(e) => setSelectedProducts({ ...selectedProducts, [data.id]: e.value })}
          className="child-fuel-grid rounded border flex-grow-1"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '2rem' }} header={"Selected"}></Column>
          <Column field="Tank_Number" header="Tank Number" />
          <Column field="Product" header="Product" />
          <Column field="Beginning_Inventory_Volume" header="Beginning Inventory Volume" editor={numberEditor}></Column>
          <Column field="Actual_Ending_Inventory_Volume" header="Actual Ending Inventory Volume" editor={numberEditor}></Column>
          <Column field="Sales" header="Sales" body={(rowData) => formatNumber(rowData.Sales)} editor={numberEditor}></Column>
          <Column field="Deliveries" header="Deliveries" editor={numberEditor} body={(rowData) => formatNumber(rowData.Deliveries)}></Column>
          <Column field="book_value" body={(rowData) => formatNumber(rowData.book_value)} header="Book Value" />
          <Column field="variance" body={(rowData) => formatNumber(rowData.variance)} header="Variance" />
          <Column field="Cumsum" body={(rowData) => formatNumber(rowData.Cumsum)} header="Cumulative Variance" />
          <Column rowEditor header={"Actions"} align={'center'} body={actionColumn} />
        </DataTable>
      </div>
    );
  };

  

  const allowExpansion = (rowData) => {
    return rowData.parent.child.length > 0;
  };

  const onRowToggle = (e) => {
    const id = e.data.parent?.id;
    if (expandedRow && expandedRow.parent?.id === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
  };

  return (
    <div className='d-flex flex-column h-100 p-3 gap-3'>
      <div className='fuel-title'>Fuel System Lines</div>
      <div className='d-flex align-items-end'>
        <div className="d-flex flex-row col-12 col-sm-8 flex-grow-1 flex-wrap gap-3">
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={selectedFacilities}
              options={sites}
              optionLabel='label'
              onChange={(e) => setSelectedFacilities(e.value)}
              placeholder="Select Facilities"
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="state">State</label>
            <MultiSelect
              value={selectedStates}
              options={stateOptions}
              onChange={(e) => setSelectedStates(e.value)}
              placeholder="Select States"
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="date">Date Range</label>
            <Calendar
              value={filters.dates}
              onChange={({ target: { name, value } }) => setFilters({ ...filters, [name]: value })}
              onHide={() => (!filters.dates?.[0] || !filters.dates?.[1]) && setFilters({ ...filters, dates: null })}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Date range"
              id="dates"
              name="dates"
              showButtonBar
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={filters.status}
              onChange={({ target: { name, value } }) => setFilters({ ...filters, [name]: value })}
              options={statusList}
              optionLabel="name"
              placeholder="Status"
              id="status"
              name="status"
            />
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-end grid_buttons gap-1">
          <Button label="Filter" onClick={onFilter} text />
          <Button label="Export" text onClick={exportCSV} />
        </div>
      </div>
      <Dialog appendTo="self" visible={!!formData} modal header={headerElement} onHide={() => setFormData(null)}>
        <FuelSystemLinesForm editData={formData} onSave={(data) => console.log(data)} onCancel={() => setFormData(null)} />
      </Dialog>
      <DataTable
        id="fuel-lines-grid"
        ref={dt}
        dataKey="id"
        showGridlines
        scrollable
        loading={isLoading}
        scrollHeight={window.innerHeight - 220}
        value={filteredData}
        size="large"
        className="fuel-system-grid rounded border flex-grow-1"
        rows={10}
        paginator
        totalRecords={fuelMapping.length}
        pageLinkSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        reorderableColumns
        resizableColumns
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRow ? [expandedRow] : null}
        onRowToggle={onRowToggle}
        emptyMessage="No records found."
      >
        <Column expander={allowExpansion} style={{ width: '3rem' }} />
        <Column field="parent.client_name" header="Client Name" />
        <Column field="parent.facility_name" header="Site Name" />
        <Column field="parent.Fule_System_Name" header="Fuel System Name" />
        <Column field="parent.Total_Volumne" header="Total Volume" />
        <Column field="parent.Period_Start" body={(rowData) => formatDate(rowData?.parent?.Period_Start)} header="Period Start" />
        <Column field="parent.Period_End" body={(rowData) => formatDate(rowData?.parent?.Period_End)} header="Period End" />
        <Column field="parent.Last_Reading" body={(rowData) => formatDate(rowData?.parent?.Last_Reading)} header="Last Reading" />
        <Column field="parent.Next_Reading" body={(rowData) => formatDate(rowData?.parent?.Next_Reading)} header="Next Reading" />
        <Column field="parent.Periodic_Sales" body={(rowData) => formatNumber(rowData?.parent.Periodic_Sales)} header="Periodic Sales" />
        <Column field="parent.Periodic_Deliveries" body={(rowData) => formatNumber(rowData?.parent.Periodic_Deliveries)} header="Periodic Deliverables" />
        <Column field="parent.Over_Under" body={(rowData) => formatNumber(rowData?.parent?.Over_Under)} header="Over/Under" />
        <Column field="parent.result" header="Leak Check Status" />
      </DataTable>


    </div>
  );
}

export default FuelSystemLines;

