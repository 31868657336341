import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    profile: null,
    loggedIn: localStorage.getItem("token") || false,
    toast:{
        severity:null,
        message:null
    }
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logIn(state, action) {
            localStorage.setItem("token", action.payload.token);
            state.loggedIn = action.payload.loggedIn;
        },
        logout(state) {
            state.loggedIn = false;
            state.profile = null;
            localStorage.removeItem("token");
            localStorage.removeItem("profile");
            window.location.reload("/login")
        },
        profile(state, action) {
            localStorage.setItem("profile", action.payload.profile);
            state.profile = action.payload.profile;
        },
        updateToast(state, action){
            state.toast = action.payload
        }
    },
});

export function UpdateToast({severity, message}) {
    return (dispatch, getState) => {
            dispatch(slice.actions.updateToast({
                severity:severity,
                message:message
            }))
    };
}

export { slice }
export default slice.reducer;

