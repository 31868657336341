import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { FUEL_MAPPING_URLS, ROLES_URLS } from "./apiUrls.ts";

export const RolesService = {
  getUsers: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.GET_USERS, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  Users: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.Group, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getPermissions: (): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.GET_PERMISSIONS, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getRolePermissions: (): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.GET_ROLE_PERMISSIONS, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  createPermission: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.CREATE_PERMISSION, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  createRole: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.CREATE_ROLE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  updateUser: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.UPDATE_USER, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelLineData: (body:any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.Group, "POST",body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
}