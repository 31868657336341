import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import "./FuelSystemLines.css";

const FuelSystemLinesForm = ({ editData, onCancel, onSave }) => {
    const initialValues = {
        ...editData,
        tankIds: editData?.tank_a ? `${editData.tank_a || ''},${editData.tank_b || ''}` : '',
    };

    const validationSchema = Yup.object({
        site_id: Yup.string().required('Site Name is required'),
        data_source: Yup.string().required('Data Source is required'),
        tankIds: Yup.string().required('Track IDs are required'),
        fuel_system_name: Yup.string().required('Fuel System Name is required'),
        product_category: Yup.string().required('Product Category is required'),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        onSave(values);
    };

    const FormikCalendar = ({ field, form, ...props }) => {
        return (
            <Calendar
                id={field.name}
                value={field.value}
                onChange={(e) => form.setFieldValue(field.name, e.value)}
                onBlur={() => form.setFieldTouched(field.name)}
                {...props}
            />
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="fuel-lines-form container py-4 px-5">
                    <Field type="hidden" name="_id" />
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="start_date">Start date</label>
                        <div>
                            <Field
                                name="start_date"
                                component={FormikCalendar}
                                dateFormat="mm/dd/yy"
                                placeholder="Select a date"
                            />
                            <ErrorMessage name="start_date" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="end_date">End date</label>
                        <div>
                            <Field
                                name="end_date"
                                component={FormikCalendar}
                                dateFormat="mm/dd/yy"
                                placeholder="Select a date"
                            />
                            <ErrorMessage name="end_date" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="start_fuel_voulme">Start Fuel Volume</label>
                        <div>
                            <Field type="text" name="start_fuel_voulme" />
                            <ErrorMessage name="start_fuel_voulme" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="end_fuel_voulme">End Fuel Volume</label>
                        <div>
                            <Field type="text" name="end_fuel_voulme" />
                            <ErrorMessage name="end_fuel_voulme" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="daily_sales">Daily Sales</label>
                        <div>
                            <Field type="text" name="daily_sales" />
                            <ErrorMessage name="daily_sales" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="daily_deliverables">Daily Deliverables</label>
                        <div>
                            <Field type="text" name="daily_deliverables" />
                            <ErrorMessage name="daily_deliverables" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className='form-btns d-flex justify-content-end gap-3 pt-3'>
                        <Button type="submit" disabled={isSubmitting}>
                            Save
                        </Button>
                        <Button type="button" severity="danger" onClick={onCancel}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FuelSystemLinesForm;
