import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { useDispatch } from 'react-redux';
import { slice as authSlice, } from "../store/slices/auth";

export const useErrorHandler = () => {
  const dispatch = useDispatch();

  return (operation) => catchError((error) => {
    dispatch(authSlice.actions.updateToast({ severity: "error", message: `Failed to ${operation} : ${error?.message}` }));
    console.error(`Error during ${operation}:`, error);
    return of(null);
  });
};