import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { LOGIN_URLS } from "./apiUrls.ts";

interface LoginBody {
    email: string;
    password: string;
}

export const AuthService = {
    login: (body: LoginBody) => {
        return new Observable<any>((observer) => {
            ApiService.call(LOGIN_URLS.LOGIN, "POST", body).subscribe({
                next: (response: any) => {
                    observer.next(response);
                    observer.complete();
                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();
                },
            });
        });
    },
    getUser: (): Observable<any> => {
        return new Observable<any>((observer) => {
          const url = LOGIN_URLS.PROFILE;
          ApiService.call(url, "GET", "").subscribe({
            next: (response: any) => {
              observer.next(response);
              observer.complete();
            },
            error: (error) => {
              observer.error(error);
              observer.complete();
            },
          });
        });
      },
      register: (body: any) => {
        return new Observable<any>((observer) => {
          ApiService.call(LOGIN_URLS.REGISTER, "POST", body).subscribe({
            next: (response: any) => {
              observer.next(response);
              observer.complete();
            },
            error: (error) => {
              observer.error(error);
              observer.complete();
            },
          });
        });
      },
}