import { Observable, forkJoin } from "rxjs";
import { BaseRequestModel } from "../utils/axios.ts";
import { Body, Method } from "../utils/types";


export const ApiService = {
  call: (route: string, method: Method, body: Body, headers?: any): Observable<any> => {
    const token = localStorage.getItem("token");
    let url = route;

    if (window.location.href.includes("localhost") || window.location.href.includes("192.")) {
      url = `https://devapiinventoryvariance.proteanapp.com/api/${route}` ;   
      // `${process.env.REACT_APP_BASE_URL}/api/${route}`;
      // url = "http://192.168.25.3:8000/" + route
    } else {
      // console.log(process.env.REACT_APP_BASE_URL,"test");
      url = `https://devapiinventoryvariance.proteanapp.com/api/${route}` ;
      // `${process.env.REACT_APP_BASE_URL}/api/${route}`;
    }

    const requestHeader = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...headers,
    };

    const newBase = new BaseRequestModel(url, method, body, requestHeader);
    return newBase.request();
  },
};
