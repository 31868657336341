export enum DASHBOARD_URLS {

}

export enum LOGIN_URLS {
    LOGIN = "login/",
    PROFILE = "profile/",
    REGISTER = "register/"
}

export enum FUEL_MAPPING_URLS {
    GET_LIST = "get_fuel_system/",
    CREATE = "create_fuel_system/",
    UPDATE = "update_file_system/",
    IMPORT = "bulk_upload/",
    Group = 'iv_group_report/'
}

export enum ANALYTICS_URL {
    GET_SITES = "search_site_name/",
    GET_STATES = "search_state/"
}

export enum REPORTING_URL {
    GET_EXPORT = "generator_variance",
    // GET_STATES = "search_state/"
}
export enum PRODUCT_URLS {
    GET_LIST = "product_category_details/",
    CREATE = "create_product_category/",
    UPDATE = "update_product_category/",
}


export enum ROLES_URLS {
    GET_USERS = "list_users/",
    GET_PERMISSIONS = "list_permissions/",
    GET_ROLE_PERMISSIONS = "list_roles/",
    UPDATE_USER = "update_user/",
    CREATE_PERMISSION = "create_permissions/",
    CREATE_ROLE = "roles/",
    Group = 'iv_group_report/'
}